/* --------------------------------
	Holiday calendar style
-------------------------------- */
#calendar {
  margin: 0 auto 2rem;
  width: 96%;
  padding: 8px 10px 20px;
  background: #f6f6f6;
  border-top: 4px solid #f15b73;
}

.calendar_title {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-weight: bold;
}

#calendar .holiday {
  background: #ef4f69;
  color: #ffffff;
}

#calendar .holiday_red {
  background: #f15b73;
  color: #ffffff;
}

#calendar td {
  text-align: center;
  color: #554130;
}

#calendar th#saturday {
  color: #3366cc;
}

#calendar th#sunday {
  color: #f15b73;
}

#calendar table {
  margin: 5px auto 0;
  padding: 0;
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  font-size: 13px;
  font-size: 1.3rem;
}

#calendar table thead {
  margin: 0 auto;
  background: #f6f6f6;
}

#calendar table thead th {
  font-weight: normal;
}

#calendar table tbody td {
  padding: 8px 6px;
  border: 1px solid #efefef;
}

#calendar table caption {
  margin: 0 auto 10px;
  padding: 0;
  text-align: center;
  font-size: 13px;
  font-size: 1.3rem;
}

/*=============================================================================

    Media Query:tablet

=============================================================================*/
/*=============================================================================

    Media Query:PC

=============================================================================*/
@media screen and (min-width: 1024px) {
  #calendar {
    width: 100%;
  }
}